import dayjs from 'dayjs'
import { PaymentIntent } from '@stripe/stripe-js'

import { Classified, ClassifiedAlert } from '../rest/types/Classified'
import { AddFeedbackParams } from '../rest/requests/feedbacks/addFeedback'
import { Offer } from '../rest/types/Offer'
import { ToggleReservationResponse } from '../rest/requests/reservations/toggleReservation'
import { AdsReplyFormValues } from '../forms/AdsReplyForm'
import { User } from '../rest/types/User'
import { isInPast } from '../helpers/DateHelpers'
import { Statistics } from '../rest/types/Statistics'

const trackEvent = (event: any) => {
  if (
    typeof window === 'undefined' ||
    (window as any).dataLayer === undefined
  ) {
    return null
  }

  return (window as any).dataLayer.push(event)
}

export default {
  pageview: (user?: User | null, statistics?: Statistics) => {
    return trackEvent({
      event: 'page_view',
      user_data: {
        login_status: user ? 'connected' : 'not_connected',
        subscription_type: 'email',
        centrale_status: {
          member_type:
            user?.role?.name === 'Full Acess' ||
            (user && !isInPast(user.subscriptionExpiration))
              ? 'admitted'
              : 'not_admitted',
          subscription_type:
            user && isInPast(user.subscriptionExpiration)
              ? 'offered'
              : user && !isInPast(user.subscriptionExpiration)
              ? 'paid'
              : '',
          is_active: !!(
            user?.role?.name === 'Full Acess' ||
            (user && !isInPast(user.subscriptionExpiration))
          ),
        },
        user_id: user?.id,
      },
      ...(statistics && {
        content_data: statistics,
      }),
      page_data: { type: 'offer', department: user?.postcode },
    })
  },
  alert_creation: (data: ClassifiedAlert) => {
    return trackEvent({
      event: 'alert_creation',
      alert_data: {
        department: data?.localisation,
        user_id: data?.user?.id,
        filters: {
          specialties: data?.specialities?.map(
            (speciality) => speciality?.name
          ),
          categories: data?.categories?.map((category) => category.name),
          term: data?.terms,
        },
        creation_date: data?.createdAt,
      },
    })
  },
  review_submit: (data: AddFeedbackParams) => {
    return trackEvent({
      event: 'review_submit',
      review_data: {
        offer_id: data?.offer,
        rating: data?.rating,
        offer_url: data?.offerSlug,
        user_id: data?.userId,
        review_text: data?.text,
      },
    })
  },
  ad_creation: (data: Classified) => {
    return trackEvent({
      event: 'ad_creation',
      review_data: {
        ad_data: {
          id: data?.id,
          type: data?.classifiedCategory?.name,
          department: data?.department,
          publication_date: data?.publishedAt,
          user_id: data?.user?.id,
        },
      },
    })
  },
  ad_view: (data: Classified) => {
    return trackEvent({
      event: 'ad_view',
      ad_data: {
        id: data?.id,
        type: data?.classifiedCategory?.name,
        department: data?.department,
        days_active: dayjs().diff(data?.publishedAt, 'day'),
        status: data?.status,
      },
    })
  },
  offer_view: (data: Offer, user: User | null) => {
    return trackEvent({
      event: 'offer_view',
      offer_data: {
        id: data?.id,
        type: data?.categories?.data
          ?.filter((category) => category.attributes.parentCategory.data)
          ?.map((category) => category.attributes.name),

        category: data?.categories?.data
          ?.filter((category) => !category.attributes.parentCategory.data)
          ?.map((category) => category.attributes.name),
        days_active: dayjs().diff(data?.publishedAt, 'day'),
        status: 'active',
      },
      user_data: {
        ...(user
          ? {
              member_type:
                user?.role?.name === 'Full Acess' ||
                (user && !isInPast(user.subscriptionExpiration))
                  ? 'admitted'
                  : 'not_admitted',
              subscription_type:
                user && isInPast(user.subscriptionExpiration)
                  ? 'offered'
                  : 'paid',
            }
          : { member_type: '', subscription_type: '' }),
      },
    })
  },
  partner_click: (brandName: string, brandLinkHref: string, userId: string) => {
    return trackEvent({
      event: 'partner_click',
      click_data: {
        // offer_id: '123',
        partner_name: brandName,
        destination_url: brandLinkHref,
        user_id: userId,
      },
    })
  },
  pre_reservation: (data: ToggleReservationResponse) => {
    return trackEvent({
      event: 'pre_reservation',
      reservation_data: {
        offer_id: data?.reservation?.offer?.id,
        offer_type: 'formation',
        user_id: data?.reservation?.user,
        timestamp: dayjs().format(),
      },
    })
  },
  ad_archive: (data: Classified) => {
    return trackEvent({
      event: 'ad_archive',
      ad_data: {
        id: data?.id,
        archive_reason: data?.filled ? 'pourvue' : 'non pourvue',
        via_urps: data?.service === 'urps',
        publication_date: data?.publishedAt,
        archive_date: data?.archivedAt,
        days_active: dayjs().diff(data?.publishedAt, 'day'),
        type: data?.classifiedCategory?.name,
        department: data?.department,
      },
    })
  },
  conversation_start: (data: AdsReplyFormValues) => {
    return trackEvent({
      event: 'conversation_start',
      conversation_data: {
        ad_id: data?.classified,
        user_id: data?.answer_user,
        conversation_type: 'new',
        ad_type: data?.fullClassified?.classifiedCategory?.name,
      },
    })
  },
  message_send: (
    conversationId: string,
    adId: string,
    userId: string | undefined
  ) => {
    return trackEvent({
      event: 'message_send',
      message_data: {
        conversation_id: conversationId,
        message_type: 'reply',
        ad_id: adId,
        user_id: userId ?? '',
      },
    })
  },
  inscription: (data: User, method: string) => {
    return trackEvent({
      event: 'inscription',
      user_data: {
        method: method,
        user_id: data?.id,
        subscription_type: method,
        registration_date: data?.createdAt,
        department: data?.postcode,
      },
    })
  },
  connexion: (user: User | undefined) => {
    return trackEvent({
      event: 'connexion',
      user_data: {
        method: user?.provider ?? '',
        user_id: user?.id ?? '',
        subscription_type: user?.provider ?? '',
        centrale_member_type:
          user?.role?.name === 'Full Acess' ||
          (user && !isInPast(user.subscriptionExpiration))
            ? 'admitted'
            : 'not_admitted',
      },
    })
  },
  new_subscription: (
    userId: string | undefined,
    paymentIntent: PaymentIntent
  ) => {
    return trackEvent({
      event: 'new_subscription',
      subscription_data: {
        user_id: userId ?? '',
        type: 'centrale',
        payment_status: paymentIntent.status,
        is_first_time: true,
        amount: paymentIntent.amount / 100,
        member_type: 'paid',
      },
    })
  },
  subscription_renewal: (
    userId: string | undefined,
    paymentIntent: PaymentIntent
  ) => {
    return trackEvent({
      event: 'subscription_renewal',
      subscription_data: {
        user_id: userId ?? '',
        type: 'centrale',
        payment_status: paymentIntent.status,
        is_first_time: false,
        amount: paymentIntent.amount / 100,
        member_type: 'paid',
      },
    })
  },
  search_pa: (
    terms: string,
    localization: string,
    categories: (string | undefined)[],
    specialities: string[],
    numberClassifieds: number,
    userId?: string,
    sessionId?: string
  ) => {
    return trackEvent({
      event: 'search_pa',
      search_data: {
        keyword: terms,
        filters: {
          department: localization,
          categories: categories,
          specialities: specialities,
        },
        results_count: numberClassifieds,
        user_id: userId ?? '',
        session_id: sessionId ?? '',
      },
    })
  },
  search_centrale: (
    search: string | undefined,
    total: number,
    filteredCategories: any,
    userId?: string,
    sessionId?: string
  ) => {
    return trackEvent({
      event: 'search_centrale',
      search_data: {
        keyword: search ?? '',
        filters: { category: filteredCategories },
        results_count: total ?? 0,
        user_id: userId ?? '',
        session_id: sessionId ?? '',
      },
    })
  },
}
